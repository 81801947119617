import MenuButton from './MenuButton.jsx';
import Hamburger from './Hamburger.jsx';

import './css/Header.css';

const Menu = ({callToAction, style, menuOptions, toggleDropdown}) => {

    return (
        <>
            <div className='menu-large' style={{justifyContent: 'flex-end'}}>
                {menuOptions.slice(0, -1).map((item, index) => <MenuButton name={item} key={index} />)}
                {callToAction}
            </div>
            <div className='menu-small' style={{justifyContent: 'flex-end'}}>
                <Hamburger style={style} toggleDropdown={toggleDropdown} />
            </div>
        </>
    );
}

export default Menu;
