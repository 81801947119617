import './css/Title.css';

const Title = ({feature, slogan, alignment='center', overrideMargin=false, accent}) => {

    const parentClassName = (alignment === 'center' ? 'title-parent-center' : 'title-parent-left');
    const featureSloganClassName = (alignment === 'center' ? 'title-feature-slogan-center' : 'title-feature-slogan-left');

    return (
        <div className={parentClassName}>
            <div className={featureSloganClassName}>
                <span className='title-feature' style={accent ? {color: accent} : null}>{feature}</span>
                <h2 className='title-slogan' style={overrideMargin ? {marginBottom: '0.83em'} : null}>{slogan}</h2>
            </div>
        </div>
    );
}

export default Title;
