import Section from "../section/Section.jsx";
import CallToAction from '../common/CallToAction.jsx';

import './css/Hero.css';

const Hero = ({palette, text, highlighted, subText, callToAction}) => {

	const validRgbValue = (value) => {
		if (value > 256) {
			return 256;
		} else if (value < 0) {
			return 0;
		} else {
			return value;
		}
	}

	const adjustBrightness = (hex, adjustment, alpha=1) => {
			const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
			return `rgba(${validRgbValue(r + adjustment)},${validRgbValue(g + adjustment)},${validRgbValue(b + adjustment)},${alpha})`;
	}

	let transformedText = text;

	if (highlighted) {
			const style = {boxShadow: `inset 0 -.5em ${adjustBrightness(palette[2], 0, .4)}`};
			const firstPart = text.split(highlighted)[0];
			const secondPart = text.split(highlighted)[1];
			transformedText = <>{firstPart}<span style={style}>{highlighted}</span>{secondPart}</>
	}

  return (
		<Section 
			className='hero-parent'
			stackable={false}
			palette={palette}
		>
			<div className='hero-text'>
				<h1>{transformedText}</h1>
				<p>{subText}</p>
				<CallToAction
					name={callToAction}
					to='/'
					style={{ backgroundColor: palette[2], color: palette[1] }}
				/>
			</div>
		</Section>
  );
}

export default Hero;
