import './css/HeroWrapper.css';


const HeroWrapper = ({header, hero, picture}) => {

  return (
    <div className='hero-wrapper' style={{backgroundImage: `url(${picture}`}}>
        {header}
        {hero}
    </div>
  )
}

export default HeroWrapper;
