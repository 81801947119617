import './css/Features.css';

const Features = ({features}) => {
    return (
      <div className="features">
        {features}
      </div>
    )
}

export default Features;
