import { Briefcase, CheckCircle, Layout, Wind } from 'react-feather';

import { CallToAction, Header, HeroWrapper, Hero, Title, Section, Features, Feature, PictureFeatures, Contact, Footer } from 'custom-components';
import hero from './hero-2.jpg';
import logo from './logo.svg';
import portrait from './portrait.jpg';
import stock from './stock.jpg';

import './App.css';

const App = () => {

  return (
    <div className="App">
      <HeroWrapper
        header={
          <Header
            logo={logo}
            menuOptions={['About', 'Contact Us']}
            palette={[null, 'white', '#228B22']}
          />
        }
        hero={
          <Hero
            text="We create great websites for your business"
            highlighted="great websites"
            subText="Put your best digital foot forward. Let us create a website for your business that is first-class."
            callToAction='Contact Us'
            palette={[null, 'white', '#228B22']}
          />
        }
        picture={hero}
      />
      <Section
        title={<Title feature='What Sets Us Apart' slogan="Great customer experience"/>}
      >
        <Features
          features={[
            <Feature
              key='1'
              title="Fast"
              icon={<Wind size={48} />}
              description="Studies show that 40% of customers leave a webpage if it doesn't load in under 3 seconds. [1]"
            />,
            <Feature
              key='2'
              title="Simple"
              icon={<Layout size={48} />}
              description="38% of online users consider most web pages to be too complex [2]"
            />,
            <Feature
              key='3'
              title="Professional"
              icon={<Briefcase size={48} />}
              description="Study done by WSJ claims professional web page is top 5 business priorities [3]"
            />
          ]}
        />
      </Section>
      <Section
        palette={['#333333', 'white', 'gray']}
      >
        <div
          pic here
        />
        <div>
          <Title feature='Industry Experience' slogan='Built by a developer you can trust' overrideMargin/>
          <p>Built by a developer you can trust</p>
        </div>
      </Section>
      <Section
        title={<Title feature='Not Interested In A Website?' slogan='We provide other services too'/>}
      >
      </Section>
      <Section
        palette={['#232f3b', 'white']}
        title={<Title feature='Contact Us' slogan='We would love to hear from you'/>}
      >
        <Contact 
          callToAction={<CallToAction name='Contact' to='/' style={{backgroundColor: '#228B22'}}/>}
        />
      </Section>
      <Footer
        logo={logo}
        slogan='We create great websites for your business'
        phone='706-612-5081'
        email='matt@mattprogramming.com'
        nav={['About', 'Contact Us']}
        style={{backgroundColor: '#333333', color: 'white'}}
      />
    </div>
  );
}

export default App;
