import Section from "../section/Section.jsx";
import './css/Footer.css';

const Footer = ({logo, slogan, phone, email, nav=[], style}) => {
    return (
        <Section style={{minHeight: 180, ...style}}>
          <div className='footer-parent'>
            <div className='footer-section'>
              <a href='/' className='footer-logo'>
                <img src={logo} alt="company logo" style={{cursor: 'pointer'}} />
              </a>
              <p className='footer-slogan'>{slogan}</p>
              <p className='footer-contact'>{phone}</p>
              <p className='footer-contact'>{email}</p>
            </div>
            <div className='divider' />
            <div className='footer-section'>
              <p className='footer-section-title'>Navigation</p>
              {nav.map((item, i) => <a key={i} href='/' className='footer-nav-item'>{item}</a>)}
            </div>
          </div>
        </Section>
    )
}

export default Footer;
