import './css/Header.css';

const MenuButton = ({name}) => {
    return (
        <a
            className='menu-button'
            href='/'
        >
            {name}
        </a>
    );
}

export default MenuButton;
