import './css/Section.css'

const Section = ({className, title, palette=['white', 'black', 'gray'], style, children}) => {

  return (

      <div 
        className={className ? "section " + className : "section"}
        style={palette ? {backgroundColor: palette[0], color: palette[1], ...style} : style}
      >
        {title}
        {children}
      </div>
  )
}

export default Section;
