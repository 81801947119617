import './css/Features.css'

const Feature = ({icon, title, description, backgroundImage}) => {
    return (
        <div className="feature" style={backgroundImage ? {backgroundImage: `url(${backgroundImage})`}: null}>
            <span className='icon'>{icon}</span>
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    )
}

export default Feature;
