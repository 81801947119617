import './css/Contact.css';

const Contact = ({callToAction}) => {

    const handleSubmit = () => {

    }

    return (
      <form className='form-parent' onSubmit={handleSubmit}>
        <div className='form-column'>
          <div className='form-row'>
            <input type="text" name="name" id="name" placeholder="Email" required />
          </div>
          <div className='form-row'>
            <textarea type="textArea" name="email" id="email" placeholder="Put your message here!" required />
          </div>
          <div className="form-submit">
            {callToAction}
          </div>
        </div>
      </form>
    )
}

export default Contact;
