import 'material-symbols/outlined.css';
import './css/PictureFeatures.css';

const PictureFeatures = ({picture, features, pictureFirst=true, icon}) => {

    const makeFeatures = (features) => {
        const elements = [];
        features.forEach((feature, i) => elements.push(<div key={i} className='symbol-feature'>{icon} {feature}</div>))
        return elements;
    }

    return (
            <div className={pictureFirst ? 'picture-features' : 'picture-features row-reverse'}>
                <img src={picture} alt='showcasing the feature' className='picture-feature' />
                <div className='picture-features-list'>
                    {makeFeatures(features)}
                </div>
            </div>
    );
}

export default PictureFeatures;
