import './css/Header.css';

const Hamburger = ({toggleDropdown, style}) => {
    return (
        <a 
            className='hamburger'
            style={style}
            onClick={toggleDropdown}
            role='button'
            tabIndex='0'
        >
            &#x2630;
        </a>
    );
}

export default Hamburger;
