import { useEffect, useState } from 'react';

import CallToAction from '../common/CallToAction.jsx';
import Section from '../section/Section.jsx';
import Menu from './Menu.jsx';

import './css/Header.css';

const Header = ({palette, logo, menuOptions, menuAlignment='right'}) => {

    const [ isDropdown, setIsDropdown ] = useState(false);

    useEffect(() => {
        window
        .matchMedia("(min-width: 1200px)")
        .addEventListener('change', () => setIsDropdown(false));
      }, []);

    const toggleDropdown = () => {
        setIsDropdown(!isDropdown);
    }

    return ( 
      <>
        <Section 
          className="header-flex"
          palette={palette}
          stackable={false}
        >
            <a 
              href='/'
            >
              <img 
                src={logo}
                alt="company logo"
                className='logo'
              />
            </a>
            {menuOptions ? (
              <Menu 
                menuOptions={menuOptions}
                menuAlignment={menuAlignment}
                callToAction={
                  <CallToAction
                    name={menuOptions[menuOptions.length - 1]}
                    to={menuOptions[menuOptions.length - 1].toLowerCase().replaceAll(" ", "-")}
                    style={{backgroundColor: `${palette[2] == null ? 'transparent' : palette[2]}`, color: palette[1], marginLeft: 24}}
                  />
                }
                toggleDropdown={toggleDropdown}
              />
              ) : null
            }
        </Section>
        <Section  
          className={isDropdown ? 'dropdown toggled' : 'dropdown'}
          palette={palette}
        >
          {menuOptions.map((option, i) =>
            <a 
              className='dropdown-option' 
              key={i} 
              href={option.toLowerCase().replaceAll(" ", "-")}
              style={{color: palette[1]}}
            >
              {option}
            </a>
          )}
        </Section>
      </>
    );
}

export default Header;
