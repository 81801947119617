import { useState } from 'react';

import './css/CallToAction.css';

const CallToAction = ({name, to, style}) => {
    
    const [backgroundColor, setBackgroundColor] = useState(style.backgroundColor);

    const adjustBrightness = (hex, adjustment, alpha=1) => {

        const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));

        return `rgba(${validRgbValue(r + adjustment)},${validRgbValue(g + adjustment)},${validRgbValue(b + adjustment)},${alpha})`;
    }
	
		const validRgbValue = (value) => {

			if (value > 256) {
				return 256;

			} else if (value < 0) {
				return 0;

			} else {
				return value;
			}
		}

    return (
      <a
        className='call-to-action'
        href={to}
        onMouseEnter={() => setBackgroundColor(adjustBrightness(style.backgroundColor, -30))}
        onMouseLeave={() => setBackgroundColor(style.backgroundColor)}
        style={{backgroundColor: backgroundColor, color: style.color}}
      >
        {name}
      </a>
    );
}

export default CallToAction;
